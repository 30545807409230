<template>
  <main>
    <h4>Tenants</h4>
    <p>List of all tenants, independent of building, goes here</p>
  </main>
</template>

<script>
export default {};
</script>

<style>
</style>